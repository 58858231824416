import React from 'react';
import { Generic, JSONLD } from 'react-structured-data';
import { graphql } from 'gatsby';

import buildBreadcrumbListItem from './buildBreadcrumbListItem';
import buildCollectionPageUrl from '../url/buildCollectionPageUrl';
import { STRUCTURED_DATA_HOMEPAGE_BREADCRUMB_ITEM } from './constants';

// Types
import { ShowroomPageBreadcrumbFragment } from './__generated__/ShowroomPageBreadcrumbFragment';

export const fragment = graphql`
  fragment ShowroomPageBreadcrumbFragment on CMS_Showroompage {
    slug
    title
  }
`;

type Props = {
  showroomPage: ShowroomPageBreadcrumbFragment;
};

const ShowroomPageBreadcrumb = ({ showroomPage }: Props) => {
  const showroomPageTitle = showroomPage.title;
  const showroomSlug = showroomPage.slug;

  if (!showroomPageTitle || !showroomSlug) {
    return null;
  }

  const showroomPageUrl = buildCollectionPageUrl(showroomSlug);

  const showroomListItem = buildBreadcrumbListItem({ name: showroomPageTitle, position: 2, url: showroomPageUrl });

  const schema = {
    itemListElement: [STRUCTURED_DATA_HOMEPAGE_BREADCRUMB_ITEM, showroomListItem],
  };

  return (
    <JSONLD dangerouslyExposeHtml={true}>
      <Generic jsonldtype="BreadcrumbList" schema={schema} />
    </JSONLD>
  );
};

export default ShowroomPageBreadcrumb;
