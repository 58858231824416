import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import LayoutContainer from '../containers/Layout';
import ShowroomPageContainer from '../containers/ShowroomPage';

// Types
import { ShowroomPageTemplateQuery } from './__generated__/ShowroomPageTemplateQuery';
import { PageRendererProps } from 'gatsby';

export const pageQuery = graphql`
  query ShowroomPageTemplateQuery($where: CMS_JSON!) {
    cms {
      showroompages(where: $where) {
        ...ShowroomPageContainerFragment
        logoColor
      }
    }
  }
`;

type Props = {
  data: ShowroomPageTemplateQuery;
} & PageRendererProps;

const ShowroomPageTemplate = ({ data }: Props) => {
  if (!data.cms.showroompages || data.cms.showroompages.length !== 1) {
    throw Error('Something went wrong');
  }

  const [showroomPage] = data.cms.showroompages;
  const logoColor = idx(showroomPage, _ => _.logoColor);

  if (!showroomPage) {
    throw Error('Something went wrong');
  }

  return (
    <LayoutContainer logoColor={logoColor}>
      <ShowroomPageContainer showroomPage={showroomPage} />
    </LayoutContainer>
  );
};

export default ShowroomPageTemplate;
