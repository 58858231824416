import React from 'react';
import { graphql } from 'gatsby';

import Heading from '../../components/Heading';
import SEOContainer from '../Layout/SEO';
import Section from '../../components/Heading/Section';
import SectionContainer from '../Section';
import buildShowroomPageSeoProps from '../../utils/seo/buildShowroomPageSeoProps';
import notNullOrUndefined from '../../utils/misc/notNullOrUndefined';
import { isNeighborClipped } from '../Section/utils';

// Types
import { ShowroomPageContainerFragment } from './__generated__/ShowroomPageContainerFragment';
import ShowroomPageBreadcrumb from '../../utils/seo/ShowroomPageBreadcrumb';

export const fragment = graphql`
  fragment ShowroomPageContainerFragment on CMS_Showroompage {
    ...BuildShowroomPageSeoPropsFragment
    ...ShowroomPageBreadcrumbFragment
    id
    title
    showroom {
      id
    }
    sections(sort: "order") {
      ...SectionContainerFragment
      id
    }
  }
`;

type Props = {
  showroomPage: ShowroomPageContainerFragment;
};

const ShowroomPageContainer = ({ showroomPage }: Props) => {
  const seoProps = buildShowroomPageSeoProps(showroomPage);

  const filteredSections = (showroomPage.sections || []).filter(notNullOrUndefined);

  const mappedSections = filteredSections.map((section, index, sections) => (
    <SectionContainer
      index={index}
      isCritical={true}
      isNeighborClipped={isNeighborClipped({ index, sections })}
      key={section.id}
      section={section}
    />
  ));

  return (
    <>
      {/* SEO */}
      <SEOContainer {...seoProps} />
      <ShowroomPageBreadcrumb showroomPage={showroomPage} />

      {!!seoProps.title && <Heading isVisuallyHidden={true}>{seoProps.title}</Heading>}
      <Section hasNoTag={true}>{mappedSections}</Section>
    </>
  );
};

export default ShowroomPageContainer;
