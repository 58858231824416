import idx from 'idx';
import { graphql } from 'gatsby';

import buildShowroomPageUrl from '../url/buildShowroomPageUrl';
import getSeoPicture from './getSeoPicture';

// Types
import { BuildShowroomPageSeoPropsFragment } from './__generated__/BuildShowroomPageSeoPropsFragment';

export const fragment = graphql`
  fragment BuildShowroomPageSeoPropsFragment on CMS_Showroompage {
    slug
    title
    showroom {
      description
      picture {
        ...SeoMetaPictureFragment
      }
    }
  }
`;

const buildShowroomPageSeoProps = (showroomPage: BuildShowroomPageSeoPropsFragment) => {
  const description = idx(showroomPage, _ => _.showroom.description);
  const picture = idx(showroomPage, _ => _.showroom.picture) || null;
  const slug = showroomPage.slug;
  const title = showroomPage.title;

  const canonicalUrl = buildShowroomPageUrl(slug);
  const image = getSeoPicture({ alt: title, picture });

  return {
    canonicalUrl,
    description,
    image,
    openGraphDescription: description,
    openGraphTitle: title,
    title,
  };
};

export default buildShowroomPageSeoProps;
